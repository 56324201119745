import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image';
import Layout from '../components/layout'
import "aos/dist/aos.css";
export default () => {

    let AOS;

    useEffect(() => {
        // Server-side rendering does not provide the 'document' object
        // therefore this import is required either in useEffect as exclusively executed on a client
        const AOS = require("aos");
        AOS.init({
            once: true,
        });
    }, []);

    useEffect(() => {
        if (AOS) {
            AOS.refresh();
        }
    });
    const [active, setActive] = useState(null)
    const [loaded, setLoaded] = useState(false)
    return (
        <StaticQuery
            query={graphql`
                query {
                    sales: file(relativePath: { eq: "careers/fresh/sales.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 660, maxHeight: 1200, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    salesMobile: file(relativePath: { eq: "careers/fresh/sales-m.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    ops: file(relativePath: { eq: "careers/fresh/operationsNEW.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 660, maxHeight: 1200, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    opsMobile: file(relativePath: { eq: "careers/fresh/operations-mNEW.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    corp: file(relativePath: { eq: "careers/fresh/corporate.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 660, maxHeight: 1200, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    corpMobile: file(relativePath: { eq: "careers/fresh/corporate-m.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }

                    dealer: file(relativePath: { eq: "careers/fresh/dealers.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 660, maxHeight: 1200, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }

                    dealerMobile: file(relativePath: { eq: "careers/fresh/dealers-m.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    vid: file(relativePath: { eq: "careers/video-image@2x.png" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 500, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            `}
            render={data => {
                const slides = [
                    {
                        name: 'sales',
                        description: `You know you can do anything you put your mind to. That’s why you should be in charge of your career and earnings. Sales gives you the freedom to secure your own future.`,
                        image: data.sales.childImageSharp.fluid,
                        mobile: data.salesMobile.childImageSharp.fluid,
                        subject: 'Sales Career Enquiry'
                    },
                    {
                        name: 'Operations',
                        description: `Work in the field with the best crews, performing the highest quality install work, delivering the best customer experience, in the business.`,
                        image: data.ops.childImageSharp.fluid,
                        mobile: data.opsMobile.childImageSharp.fluid,
                        subject: 'Operations Career Enquiry'
                    },
                    {
                        name: 'corporate',
                        description: `Love what you do and who you do it with. Work should never feel like a chore, and working with KOTA corporate never does. Come join the team.`,
                        image: data.corp.childImageSharp.fluid,
                        mobile: data.corpMobile.childImageSharp.fluid,
                        subject: 'Corporate Career Enquiry'
                    },
                    {
                        name: 'dealers',
                        comp: DealerComponent,
                        image: data.dealer.childImageSharp.fluid,
                        mobile: data.dealerMobile.childImageSharp.fluid,
                        subject: 'Dealers Career Enquiry'
                    }
                ]

                const aosA = loaded ? {} : {
                    'data-aos': 'fade-in',
                    'data-aos-delay': 500,
                    'data-aos-duration': 1000
                }
                const aosB = loaded ? {} : {
                    'data-aos': 'fade-in',
                    'data-aos-delay': 1000,
                    'data-aos-duration': 1000,
                }
                return (
                    <Layout showQuote={false} formType='careers'>
                        <CareersPage className="hero is-fullheight">
                            <div className="overlay hero  is-fullheight">
                                <div className="container">
                                    <div className="columns">
                                        {slides.map((s, i) => (
                                            <div className={'column is-one-quarter ' + (i == active ? 'active-background' : '')} key={'career_' + i} {...aosA}>
                                                <a>
                                                    <BackgroundImage
                                                        fluid={[
                                                            s.mobile,
                                                            {
                                                                ...s.image,
                                                                media: `(min-width: 769px)`,
                                                            },
                                                        ]}
                                                        onMouseEnter={() => setActive(i)}
                                                        onMouseLeave={() => {
                                                            setActive(null)
                                                            setLoaded(true)
                                                        }}
                                                    >
                                                        {
                                                            i == active ? (
                                                                <div className="active-panel">
                                                                    <div>
                                                                        <p className="fs-35 m-fs-22 s-fs-28 fw-600 gibson is-uppercase name" style={{ color: '#fff' }}>
                                                                            {s.name}
                                                                        </p>
                                                                        {s.comp ? <s.comp /> : (
                                                                            <>
                                                                                <p className="description fs-15 m-fs-10 fw-100 roboto">
                                                                                    {s.description}
                                                                                </p>
                                                                                <Img fluid={data.vid.childImageSharp.fluid} />
                                                                            </>
                                                                        )}


                                                                    </div>
                                                                    <a className='our-button fs-18 m-fs-11 s-fs-14 roboto  fw-100' href={`mailto:info@kotasolar.com?subject=${s.subject}`}>
                                                                        Express your interest
                                                                        </a>

                                                                </div>
                                                            )
                                                                :
                                                                <p className="fs-35 m-fs-22 s-fs-28 fw-600 gibson is-uppercase" style={{ color: '#fff' }} {...aosB}>
                                                                    {s.name}
                                                                </p>
                                                        }
                                                    </BackgroundImage>
                                                </a>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </CareersPage>
                    </Layout>
                )
            }}
        />
    )
}


const DealerComponent = () => (
    <DealerDiv className="description dealer fs-15 fw-100 roboto">
        <p className="fs-15 m-fs-10 s-fs-15"><strong>Already a Dealer?</strong></p>
        <p className="fs-15 m-fs-10 s-fs-15">Jump on board with Kota.</p>
        <p className="fs-15 m-fs-10 s-fs-15">Here are just a some of the perks:</p>
        <ul className="fs-13 m-fs-7 s-fs-13">
            <li>Lightning fast turnaround times</li>
            <li>Best sales and Pipeline management tech in the industry</li>
            <li>Thorough and constant project communication</li>
            <li>Highest quality of installation. Our guy literally wrote the handbook on quality &amp; safety</li>
            <li>Top tier standard equipment: Solaria 360w and Emphase on every job</li>
        </ul>
    </DealerDiv>
)

const DealerDiv = styled('div')`
    p{
        text-align: center;
    }
    ul{
        padding-top: 25px;
    }
    li{
        margin-bottom: 1.5em;
        @media screen and (max-width: 1199px){
            margin-bottom: 0.8em;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape){
            line-height: 1.5em;
        }
        padding-left: 16px;
        position: relative;
        &:before{
            content: '';
            width: 6px;
            height: 6px;
            margin-left: -16px;
            top: 0.8em;
            background: #fff;
            position: absolute;
            border-radius: 100%;
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }
`
const CareersPage = styled('div')`
    background: #222;
    .overlay{
        background: #000;
    }
    .container{
        width: 100%;
        @media screen and (max-width: 1199px){
            width: calc(100% - 40px);
        }
        height: calc(100vh - 100px);
        padding-top: 100px;
        max-height: 800px;
        .columns{
            height: 100%;
            .column{
                border-radius: 4px;
                & > a > div{
                    height: 100%;
                    border-radius: 4px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &::before{
                        opacity: 1
                    }
                }

                &.active-background{
                    & > a > div:before, & > a > div:after{
                        transition: 0.333s !important;
                        -webkit-filter: blur(28px) opacity(0.3333) !important;
                        -moz-filter: blur(28px)  opacity(0.3333)!important;
                        -o-filter: blur(28px)  opacity(0.3333)!important;
                        -ms-filter: blur(28px)  opacity(0.3333)!important;
                        filter: blur(28px) opacity(0.3333)!important;
                    }
                }
            }
        }
        .active-panel{
            transition: 0.333s;
            padding: 50px 15px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            .our-button{
                background: none;
                color: #fff;
                border: 1px solid #707070;
                width: 100%;
                padding: 13px;
                border-radius: 4px;
                cursor: pointer;
                text-align: center;
                
            }
            .name{
                text-align: center;
            }
            .description{
                
                padding-top: 29px;
                padding-bottom: 19px;
                @media screen and (max-width: 1199px){
                    padding-top: 3.515625rem;
                    padding-bottom: 2.473958333333333rem;
                }
                @media screen and (max-width: 420px){
                    padding-top: 5.072463768rem;
                    padding-bottom:	4.106280193rem;
                }

                @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape){
                
            }
            .gatsby-image-wrapper{
                border-radius: 4px;
                div{
                    background: none;
                }
            }
            
        }
    }


    @media screen and (max-width: 768px){
        .container{
            min-height: calc(100vh - 50px);
            max-height: initial;
            height: initial;
            .columns{
                min-height: calc(100vh - 50px);
                flex-direction: column;
                display: block;
                .column{
                    height: 40vh;
                    &.active-background{
                        height: auto;
                        .our-button{
                            margin-top: 37px;
                        }
                    }
                }
                padding-bottom: 50px;
            }
        }
    }

    @media screen and (max-width: 420px){
        .container{
            .columns{
                .column{
                    height: 29vh;
                    &.active-background{
                        .active-panel{
                            padding: 12.0772946rem;
                            padding-top: 13rem;
                        }
                        .our-button{
                            padding-left: 0;
                            padding-right: 0;
                            width: 100%;
                            margin-top: 8.937198068rem;
                        }
                    }
                }
                
            }
        }
    }
`